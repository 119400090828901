import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { time } from 'console';
import { getUserSchoolYearName } from 'src/app/utils/generalImplementations';

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.html',
    styleUrls: ['./alert-dialog.scss']
})
export class AlertDialogComponent implements OnInit {
    title: string;
    message: string;
    subTitle: string;
    showButton: boolean;
    buttonMessage: string;
    theme: string;
    setYear: boolean = false;
    schoolYear: string | undefined;
    schoolYears: any[] = [];
    schoolYearError: boolean = false;
    isLoading: boolean = false;
    showBlackFriday: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        private router: Router,
        private userService: UserService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: AlertDialogModel
    ) {
        // Update view with given values
        this.title = data.title;
        this.subTitle = data.subTitle;
        this.message = data.message;
        this.setYear = data.setYear;
        this.showButton = data.showButton;
        this.buttonMessage = data.buttonMessage ? data.buttonMessage : "Rinnova Ora";
        this.theme = data.theme ? data.theme : "red";
        this.showBlackFriday = data.showBlackFriday != undefined ? data.showBlackFriday : true;

        // init school years
        environment.configuration.userSchoolYearType.forEach((usyt: any) => {
            this.schoolYears.push(
                {
                    value: usyt.type,
                    name: getUserSchoolYearName(usyt.type)
                },
            )
        });
    }

    ngOnInit() {
    }

    onConfirm(): void {
        // Close the dialog, return true
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false);
    }

    goToAccount() {
        this.router.navigate(['/account']);
        this.dialogRef.close(true);
    }

    notify(message: string, error: boolean) {
        this.snackBar.open(message, 'Close', {
            duration: 5000,
            panelClass: error ? 'error-snackbar' : 'success-snackbar',
        });
    }

    async updateUser() {
        if (this.isLoading) return;
        if (!this.schoolYear) {
            this.schoolYearError = true;
            return;
        }
        this.isLoading = true;
        const res = await this.userService.updateUser({
            school_year: this.schoolYear
        })
        if (res.success) {
            this.schoolYearError = false;
            this.notify('Aggiornamento Avvenuto Con Successo', false);
            environment.user = res.user;
            this.onConfirm();
        }
        else {
            this.isLoading = false;
            this.notify("C'è stato un errore durante l'aggiornamento", true);
        }
    }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class AlertDialogModel {

    constructor(public title: string, public setYear: boolean, public subTitle: string, public message: string = "Confirm", public showButton: boolean = false, public buttonMessage?: string, public theme?: string, public showBlackFriday?: boolean) {
    }
}
