import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from "@angular/platform-browser";
import { environment } from '../../src/environments/environment';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { NavigationEnd, Router } from '@angular/router';

declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    environment = environment;
    checkApiLoadedInterval: any;
    checkIubendaLoadedInterval: any;
    user: any = environment.user;

    // @HostListener('click', ['$event']) onClick($event : any){
    //     gtag('event', "click", {
    //         'type_of_click': "create-room"
    //     });
    // }

    constructor(
        private window: Window,
        private elementRef: ElementRef,
        private titleService: Title,
        private dialog: MatDialog,
        private router: Router
    ) {
        if (environment.production) {
            // init google analytics only for production
            const gtag = (window as any)["gtag"];
            if (gtag) {
                gtag('js', new Date());
                gtag('config', 'G-ET135FQK6S');
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationEnd) {
                        gtag('config', 'G-LFEKDJFN51', { 'page_path': event.urlAfterRedirects });
                    }
                })
            }
        }
    }

    ngOnInit() {
        this.titleService.setTitle(environment.appTitle);
        this.loadSwarmifyApi().then(() => { });
        this.loadIubenda();
        this.loadGoogleTagManager();
        this.checkForSubscription();
        this.checkForSchoolYear();
    }

    checkForSchoolYear() {
        if (Object.keys(this.user).length === 0) {
            return;
        }
        const schoolYear = this.user.school_year;
        if (!schoolYear) {
            this.openNoSchoolYearBanner();
        }
    }


    checkForSubscription() {
        if (Object.keys(this.user).length === 0) {
            return;
        }
        if (this.user.first_login) {
            return;
        }
        const hasSub = this.user.subscription && this.user.subscription.status === 'active';
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (!hasSub && !code) {
            this.openNoSubBanner();
        }
    }

    openNoSchoolYearBanner(): void {
        if (window.location.href.includes('success-payment')) return;
        if (window.location.href.includes('maintenance')) return;
        const isMobile = window.innerWidth <= 768;
        const dialogWidth = isMobile ? "300px" : "500px";

        const title = "Aggiornamento Funzionalità";
        const subtitle = "Abbiamo tante novità per te, per poterle utilizzare ti chiediamo di selezionare il tuo anno scolastico.";

        const dialogRef = this.dialog.open(AlertDialogComponent, {
            maxWidth: dialogWidth,
            backdropClass: 'my-backdrop',
            disableClose: true, // Add this line to disable closing the dialog by clicking outside
            data: {
                theme: "blue",
                title: title,
                setYear: true,
                subTitle: subtitle,
                showBlackFriday: false,
                showButton: !(window.location.href.includes('signup') || window.location.href.includes('login')),
                buttonMessage: "Imposta Ora"
            }
        });

        dialogRef.afterClosed().subscribe((dialogResult: any) => {
            if (dialogResult) {
                this.window.location.reload();
            }
        });
    }

    openNoSubBanner(): void {
        if (window.location.href.includes('success-payment')) return;
        if (window.location.href.includes('maintenance')) return;
        const isMobile = window.innerWidth <= 768;
        const dialogWidth = isMobile ? "300px" : "500px";

        let title = environment.user.subscription?.type === "freemium" ? "Sei un utente Free" : "Abbonamento Scaduto";
        let subtitle = environment.user.subscription?.type === "freemium" ? "Abbonati per ottenere di più!" : "Il tuo piano di abbonamento è scaduto";
        let message = environment.user.subscription?.type === "freemium" ? `Vai alla pagina account e attiva il tuo abbonamento per utilizzare tutti i servizi di Tutì.` : `Vai alla pagina account e rinnova il tuo abbonamento per continuare ad utilizzare tutti i servizi di Tutì.`;
        let buttonMessage = environment.user.subscription?.type === "freemium" ? "Attiva Ora" : "Rinnova Ora";


        if (environment.user.subscription === undefined || environment.user.subscription === null) {
            title = "Non hai un abbonamento attivo";
            subtitle = "Abbonati per ottenere di più!";
            message = "Vai alla pagina account e attiva il tuo abbonamento per utilizzare tutti i servizi di Tutì.";
            buttonMessage = "Attiva Ora";
        }

        const dialogRef = this.dialog.open(AlertDialogComponent, {
            maxWidth: dialogWidth,
            backdropClass: 'my-backdrop',
            data: { title: title, subTitle: subtitle, message: message, showButton: !(window.location.href.includes('signup') || window.location.href.includes('login')), buttonMessage: buttonMessage }
        });

        // dialogRef.afterClosed().subscribe((dialogResult: any) => {
        //     if (dialogResult) {

        //     }
        // });
    }

    loadSwarmifyApi() {
        return new Promise<any>((resolve, reject) => {
            if (!environment.swarmifyLoaded) {
                const swarmifyKey = environment.configuration.swarmifyCDNKey;
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.src = `https://assets.swarmcdn.com/cross/swarmdetect.js`;
                this.elementRef.nativeElement.appendChild(s);
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.text = `var swarmoptions = {
                    swarmcdnkey: "${swarmifyKey}",
                    iframeReplacement: "iframe",
                    theme: {
                        primaryColor: "#BEE530",
                    },
                    autoreplace: {
                        youtube: false
                    }
                };`;
                this.elementRef.nativeElement.appendChild(s);
                this.checkApiLoadedInterval = setInterval(() => {
                    if ((window as any).SWARMIFY_LOADED) {
                        environment.swarmifyLoaded = true;
                        clearInterval(this.checkApiLoadedInterval);
                        resolve(true);
                    }
                }, 300);
            }
            else {
                resolve(true)
            }
        });
    }

    loadIubenda() {
        return new Promise<any>((resolve, reject) => {
            if (environment.production) {
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.text = `var _iub = _iub || [];
                _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"cookiePolicyInOtherWindow":true,"floatingPreferencesButtonDisplay":"bottom-right","perPurposeConsent":true,"siteId":3557997,"whitelabel":false,"cookiePolicyId":29835548,"lang":"it","cookiePolicyUrl":"https://www.tuti.education/cookie-policy", "banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0073CE","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","closeButtonRejects":true,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-top-center","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#0073CE","rejectButtonDisplay":true,"showTitle":false,"textColor":"#000000","usesThirdParties":false }};`;
                this.elementRef.nativeElement.appendChild(s);
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.src = `https://cs.iubenda.com/autoblocking/3557997.js`;
                this.elementRef.nativeElement.appendChild(s);
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.src = `//cdn.iubenda.com/cs/iubenda_cs.js`;
                s.charset = "UTF-8";
                s.async = true;
                this.elementRef.nativeElement.appendChild(s);
                resolve(true);
            }
            else {
                resolve(true)
            }
        });
    }

    loadGoogleTagManager() {
        if (environment.production) {
            const gtmScript = document.createElement('script');
            gtmScript.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W92PTXB4');
        `;
            document.head.appendChild(gtmScript);

            const gtmNoScript = document.createElement('noscript');
            const gtmIframe = document.createElement('iframe');
            gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=GTM-W92PTXB4`;
            gtmIframe.height = "0";
            gtmIframe.width = "0";
            gtmIframe.style.display = "none";
            gtmIframe.style.visibility = "hidden";
            gtmNoScript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W92PTXB4" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
            document.body.prepend(gtmNoScript);
        }
    }
}
